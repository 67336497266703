.svg {
  display: block;
  margin: 20px auto;
  max-width: 100%;
}

.svg-circle-bg {
  fill: none;
}

.svg-circle {
  fill: none;
  transform: rotate(-90deg);
  transform-origin: center;
}
.svg-circle-text {
  font-size: 35px;
  font-family: 'niveau-grotesk';
  text-anchor: middle;
  fill: rgb(0, 0, 0);
  font-weight: 300;
}
